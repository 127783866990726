import { AfterContentInit, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-animated-button',
    templateUrl: './animated-button.component.html',
    styleUrls: ['./animated-button.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class AnimatedButtonComponent implements AfterContentInit {

    @Input() disabled = false;
    @Input() menuTitle: string;
    @Input() forceClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }

    ngAfterContentInit(): void {
        this.forceClose.subscribe((state: boolean) => {
            if (state) {
                const ddMenus = document.querySelectorAll('.dd-menu');
                ddMenus.forEach((menu) => {
                    menu.classList.remove('active');
                });

                const titleMenu = document.querySelector('#title');
                if (titleMenu) titleMenu.classList.remove('active');
            }
        })

        const buttonHasLoadingCapability = (someHTML: MouseEvent): boolean => {
            // for buttons that should be able to load before it closes the animated dropdown menu: 
            // add className "hasLoadingCapability" to the specific button, 
            // and then when loading stops call this.forceClose.next(true) that is a subscription on this component
            const loadingCapabilityBtnNodes = document.querySelectorAll('.hasLoadingCapability');
            let isElementIncluded = false;
            for (let index = 0; index < loadingCapabilityBtnNodes.length; index++) {
                if (someHTML.target === loadingCapabilityBtnNodes[index]) {
                    isElementIncluded = true;
                    break;
                };
            }

            return isElementIncluded;
        }

        document.querySelector('html')?.addEventListener('click', (mouseEvent: MouseEvent) => {
            const activeMenu = document.querySelector('.dd-menu.active');
            const grp = document.querySelector('#animated-button-grp');
            if (
                !grp ||
                mouseEvent.target === activeMenu ||
                buttonHasLoadingCapability(mouseEvent)
            ) {
                return;
            }

            const ddMenus = document.querySelectorAll('.dd-menu');
            ddMenus.forEach((menu) => {
                menu.classList.remove('active');
            });

            const titleMenu = document.querySelector('#title');
            if (titleMenu) titleMenu.classList.remove('active');
        });

        document.querySelectorAll('.drop').forEach((drop) => {
            drop.addEventListener('click', (e) => {
                e.stopPropagation();
                const ddMenus = document.querySelectorAll('.dd-menu');
                ddMenus.forEach((menu) => {
                    menu.classList.toggle('active');
                });

                const titleMenu = document.querySelector('#title');
                if (titleMenu) titleMenu.classList.toggle('active');
            });
        });
    }

}
