<div class="dd" id="animated-button-grp">
    <button class="drop btn-secondary btn btn-tiny" [disabled]="disabled">
        <img *ngIf="!menuTitle" src="../../../assets/icons/menu-dots.svg" alt="">
        <div class="menu-title-wrapper" *ngIf="menuTitle">
            <div id="title">{{menuTitle}}</div>
        </div>
    </button>

    <div class="dd-menu">
        <ng-content></ng-content>
    </div>
</div>